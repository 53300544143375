function Language() {
  return (
    <div>
      <h3>Mes langues</h3>
      <ul>
        <li>Français (natif)</li>
        <li>Anglais (compétence professionnelle)</li>
      </ul>
    </div>
  );
}

export default Language;
