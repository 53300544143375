function Education() {
  return (
    <div>
      <h3>Mes formations</h3>
      <ul>
        <li>Licence Web et Mobile, Développement Web, Conservatoire National des Arts et Métiers, 2021 - 2022</li>
        <li>BTS Services Informatiques aux Organisations, Développement Web, Lycée Saint-Vincent de Senlis, 2019 - 2021</li>
      </ul>
    </div>
  );
}

export default Education;
